<template>
        <Modal 
            v-if="qrmodal.visible"
            :modal="qrmodal"
            @closeModal="qrmodal.visible = false"
            :cssClasses="{'is-full-vw': true,'is-full-height': true}"  
        >
            <template v-slot:header>
                QR CODE SCANNER
            </template>
            <template v-slot class="is-full-width">
                <div class="has-background-light is-full-width is-full-height">
                    Scan Items container QR Code
                    <QRCodeScanner @result="handleQRScanResult" :switchCameraTrigger="qrmodal.switchCameraTrigger" />
                </div>
            </template>
            <template v-slot:footer>
                <button class="button has-background-pink-1 has-text-light" @click="qrmodal.switchCameraTrigger +=1">SWITCH CAMERA</button>
                <button class="button has-background-pink-1 has-text-light" @click="qrmodal.visible = false">CANCEL</button>
            </template>
        </Modal>
        <div id="addDingLocation" class="is-full-width broad-input m-1 is-flex is-flex-direction-column">
        <div class="is-flex is-justify-content-flex-start is-full-width">
            <div class="has-text-weight-bold">{{showAddStorageOverAddLocation ? "Storage" : "Location (make item a storage location)"}}</div>
            <div>
                <Icon :icon="!showAddStorageOverAddLocation?['fas', 'map']:['fas','warehouse']" classOverwrite="has-text-green-1 ml-1" @click="showAddStorageOverAddLocation = !showAddStorageOverAddLocation"/>
            </div>
        </div>
        <div class="is-flex">
           <Autocomplete
                  v-if="showAddStorageOverAddLocation"
                  id="storageinput"
                  ref="itemStorageInput"
                  debounce=800
                  use-html-for-results=true
                  input-class="input is-rounded is-full-width"
                  results-container-class="autocomplete-result-container"
                  results-item-class="green-on-hover vue3-results-item"
                  placeholder="search"
                  @input="handleItemStorageUpdate"
                  :results="itemStorageResults"
                  @onSelect="handleItemStorageSelect($event,itemStorageInput)"
                  @blur="clearAutocomplete(itemStorageInput)"
                  autocomplete="off"
          ></Autocomplete>
          <div v-else class="is-full-width has-background-green-5 p-1">
            <LocationInput class="is-full-width" @locationUpdate="handleLocationInputUpdate"/>
            <div class="is-flex is-align-items-center is-full-width">
                lat/lng 
                <input disabled @input="handleItemLocationUpdate(newItemLocation.value,$event)" ref="newItemLocation" class="input m-1" placeholder="lat,long: create a new location">
                <!-- <Icon class="button is-full-height m-1 has-background-dark has-text-light" :icon="['fas', 'map-marker-alt']" @click="getDeviceLocation"/> -->
            </div>
            
            <div class="is-flex is-align-items-center is-full-width">
                <div>radius </div> <input @input="handleItemLocationUpdate(newItemLocation.value,$event)" ref="newRadius" class="input m-1 is-text-float-right" placeholder="radius in meters" type=number value=100>m
            
            </div>
            
          </div>
          <Icon
          v-if="showAddStorageOverAddLocation"
          :icon="['fas', 'qrcode']"
          class="has-text-dark is-size-4 m-2 is-float-right"
          @click.stop="qrmodal.visible = true"
          />
        </div>
        <div v-if="insideof">inside of id: {{insideof}}</div>
        <div v-if="itemRootLocation && (atLocation?.id || insideof || atLocation?.new)" class="micromap">
            <Collapsible>
                <template v-slot:header>
                             location: {{atLocation.name}} <Icon :icon="['fas', 'map']" class="has-text-dark is-size-6 m-2"/>
                </template>
                 <template v-slot>
                    <div class="p-2 has-background-light is-overflow-scroll addDingMapContainer">
                        <Map class="is-90-pc-height" :area="itemRootLocation" :zoom="14.5"/>
                    </div>
                 </template>
            </Collapsible>
        </div>
      </div>
</template>

<script>
import Map from '@/components/Location/Map'
import LocationInput from '@/components/Location/LocationInput'
import Autocomplete from '@/components/Template/Autocomplete.vue'
import Collapsible from '@/components/Template/Collapsible.vue'
import QRCodeScanner from '@/components/ItemForm/QRCodeScanner.vue'
import Modal from '@/components/Template/Modal.vue'
import { ref } from '@vue/reactivity';
import { useStore } from "vuex";
import { computed, onMounted, watch } from '@vue/runtime-core';

import circle from '@turf/circle'

export default {
    name:"addDingLocation",
    props:['item','showGeolocation'],
    components:{
        Autocomplete,
        Map,
        LocationInput,
        Collapsible,
        Modal,
        QRCodeScanner,
    },
    emits:["locationUpdate"],
    setup(props,{emit}){
        const store = useStore();

        const itemStorageInput = ref(null);
        const itemStorageResults = ref([]);
        const itemRootLocation = ref(null);

        const newItemLocation = ref(null);
        const newRadius = ref(null);

        const atLocation = ref(props.item.atLocation);
        const insideof = ref(props.item.insideof);

        const showAddStorageOverAddLocation = ref(true);

        const qrmodal = ref({
            visible:false,
            switchCameraTrigger:0,
        })

        watch(computed(()=>props.item),()=>{
            if(!props.item.id){return} // prevent this from triggering after we cleared the form
            insideof.value = props.item.insideof;
            
            if(insideof.value){
                handleItemStorageUpdate(insideof.value)
            }
            else{
                atLocation.value = {id:props.item["location.id"],name:props.item["location.placename"],geoJSON:props.item["location.geoJSON"]}
                updateLocationFromItemOrItemContainer()
            }
        } )

        const handleItemLocationUpdate = async (e)=>{
                if (e.length > 3){
                    console.log("checking coords");
                    // // GEOGRAPHIC COORDINATES:
                    let coordinates = e.split(",");
                    if (coordinates.length >= 2 && 
                        coordinates.length < 4 && 
                        isNumeric(coordinates[0]) && 
                        isNumeric(coordinates[1]) && 
                        isNumeric(coordinates[coordinates.length-1])
                        ){
                            //let radius = newRadius.value.value || 1000 ;
                            //console.log("handleItemLocationUpdate",`coords: ${coordinates} ${radius}`);

                        let center = { 
                                
                                "type": "Point",
                                "coordinates": [
                                    parseFloat(coordinates[0]),
                                    parseFloat(coordinates[1])
                                ]
                               
                        }

                        let options = {steps: 20, units: 'meters'};
                        let geocircle = circle(center, newRadius.value.value, options);

                        atLocation.value = {new:{geography:geocircle}}
                        
                        await updateLocationFromItemOrItemContainer();
                
                        emit("locationUpdate",{insideof:null,atLocation:atLocation.value,"location.insideofArray":null})

                    }
                }     
        }

        const handleLocationInputUpdate = (e)=>{
            if (!e.center) return
            handleItemLocationUpdate(`${e.center}`);
            newItemLocation.value.value = `${e.center}`;
        }

        const handleItemStorageUpdate = async (e)=>{
            itemStorageResults.value = [];
            atLocation.value = null;
            insideof.value = null;
            
            if (e.length > 0){
                // search for items by ids (more for validation and to fill out geolocation)
                let searchResultsFromItemId = await store.dispatch("getItem",e.trim()).catch(()=> {return null});
                
                if (searchResultsFromItemId) {
                    handleItemStorageSelect(searchResultsFromItemId,itemStorageInput.value)
                    return
                }
            } 
            
            if (itemStorageResults.value.length === 0 && e.length > 3){
                    // search for items by name
                let searchResultsFromName = await store.dispatch("searchItems",{text:e}).catch(()=>{ return {items:[]}})
                    itemStorageResults.value = [...searchResultsFromName.items]
            }
            
            //console.log("handleItemStorageUpdate Results:",itemStorageResults.value); // user can now choose from autocomplete
            }

            const handleItemStorageSelect = async (e,target)=>{
                //console.log("handleItemStorageSelect",e.id,e);
                // TODO: check if input is either: valid thing id OR valid location id
                if (e.id === props.item.id){
                    alert("item cannot be contained by itself!");
                    throw 'item cannot be contained by itself'
                }
                target.setText(e.name);
                insideof.value = e.id;
                
                atLocation.value = {name:e["location.placename"],geoJSON:e["location.geoJSON"]}
                
                await updateLocationFromItemOrItemContainer();
                
                emit("locationUpdate",{insideof:insideof.value,atLocation:atLocation.value,"location.insideofArray":await getProjectedContainerArray()})
            }

             const getProjectedContainerArray = async ()=>{
                if (props.item.insideof == insideof.value){ // use original if nothing changed
                    return props.item["location.insideofArray"]
                }
                let newContainer = await store.dispatch("getItem",insideof.value);
                let projectedContainerArray = [insideof.value];
                projectedContainerArray = newContainer["location.insideofArray"]?.concat(projectedContainerArray);
                
                if (projectedContainerArray?.includes(props.item.id)){
                    alert("item cannot be contained by itself! item id is inside of projected new containerpath.");
                    throw 'item cannot be contained by itself item id is inside of projected new containerpath.'
                }
                return projectedContainerArray || [insideof.value]
            }

            const itemRootContainer = ref(null)

            const updateLocationFromItemOrItemContainer = async () =>{
            //console.log("new geolocation:",atLocation.value.new?.geography,"old geolocation:",atLocation.value.geoJSON);
            let geolocation = JSON.stringify(atLocation.value.new?.geography) || atLocation.value.geoJSON;
            if(geolocation){
                //console.log("it has a geolocation", geolocation);
                itemRootLocation.value = JSON.parse(geolocation);
            }
            else {
                console.log("no geolocation. must fetch", insideof.value);
                let container = await store.dispatch("getItemRootContainer",insideof.value);
                itemRootLocation.value = JSON.parse(container["location.geoJSON"]);
            }
        }

        const handleQRScanResult = async(e)=>{
            console.log("qr result",e,itemStorageInput);
            if (e.startsWith("http://") || e.startsWith("https://") ){
                let urlSplit = e.split('/');
                let itemId = urlSplit[urlSplit.length - 1];
                console.log(urlSplit,itemId);
                if(!itemId){return}
                itemStorageInput.value.setText(itemId);
                handleItemStorageUpdate(itemId)
                qrmodal.value.visible = false;
                return
            }
            store.dispatch('triggerToast',{message:"QRScanner: not an dingsda item URL"});
        }

        const clearAutocomplete = (source) => {
            source.value = []
        }

        const getDeviceLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{
                    //console.log(position);
                    newItemLocation.value.value = `${position.coords.longitude},${position.coords.latitude}`;
                    handleItemLocationUpdate(newItemLocation.value.value)
                });
            } else {
                alert("please allow geolocation in your browser.")
            }
        }

        onMounted(()=>{
            console.log("mounting");
            if(props.showGeolocation){
                //console.log(props.showGeolocation);
                showAddStorageOverAddLocation.value = !props.showGeolocation
            }
        })

        /////// HELPER FUNCTIONS ////////

        function isNumeric(val) {
            return !isNaN(val - parseFloat(val));
        }

    return{
            atLocation,
            insideof,
            itemRootLocation,
            itemStorageInput,
            itemStorageResults,
            handleItemStorageUpdate,
            handleItemStorageSelect,
            updateLocationFromItemOrItemContainer,
            clearAutocomplete,
            itemRootContainer,
            qrmodal,
            handleQRScanResult,
            showAddStorageOverAddLocation,
            handleItemLocationUpdate,
            newItemLocation,
            newRadius,
            getDeviceLocation,
            handleLocationInputUpdate,
        }
    }

}
</script>

<style>

</style>